import LazyVideo from "@components/LandingPageComponents/LazyVideoComponent";
import AwardsSection from "@components/new-ui/AwardsSection";
import ClientLogos from "@components/new-ui/ClientLogos";
import CTAButtons from "@components/new-ui/CTAButtons";
import CTASection from "@components/new-ui/CTASection";
import FeaturesSection from "@components/new-ui/FeaturesSection";
import IntegrationSection from "@components/new-ui/IntegrationSection";
import KeyFeaturesSection from "@components/new-ui/KeyFeaturesSection";
import NewLayout from "@components/new-ui/Layout";
import ProblemStatementSection from "@components/new-ui/SpotlightSection";
import TestimonialSection from "@components/new-ui/TestimonialSection";
import "keen-slider/keen-slider.min.css";
import Head from "next/head";
import Image from "next/image";
import React from "react";

const Home: React.FC = () => {
    return (
        <NewLayout
            keywords="omnichannel customer support software, whatsapp business api, whatsapp chatbot, whatsapp shared inbox, customer success management, lead management"
            title="Gallabox | WhatsApp Business Automation & WhatsApp chatbots"
            description="Gallabox is a no-code workspace that unlocks the power of WhatsApp to scale your business with Shared Inbox, WhatsApp no-code Chatbot, WhatsApp Broadcasts and more"
            withLiveChat
            canonical="https://gallabox.com"
            ogAttributes={{ image: "https://assets.gallabox.com/gb-home/new-landing/og-gallabox.png" }}
            showBanner
        >
            <Head>
                <script src="https://fast.wistia.com/embed/medias/730lmkyzty.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
                <link rel="alternate" hrefLang="en" href="https://gallabox.com/" />
                <link rel="alternate" hrefLang="ar" href="https://gallabox.com/ar" />
                <link rel="alternate" hrefLang="x-default" href="https://gallabox.com/" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        legalName: "Gallabox",
                        description:
                            "Gallabox is an Indian SaaS product that helps SMBs automate their customer conversations on WhatsApp. It’s best known for their drag & drop WhatsApp chatbot builder.",
                        url: "https://gallabox.com",
                        logo: "https://gallabox.com/gallabox-logo.svg",
                        email: "team@gallabox.com",
                        parentOrganization: "MangoLeap",
                        sameAs: [
                            "https://www.facebook.com/gallaboxofficial",
                            "https://twitter.com/gallabox",
                            "https://www.instagram.com/gallabox",
                            "https://www.linkedin.com/company/gallabox/",
                            "https://www.youtube.com/c/Gallabox/videos",
                        ],
                        foundingDate: "2021",
                        founders: [
                            {
                                "@type": "Person",
                                name: "Karthik Jagannathan",
                            },
                            {
                                "@type": "Person",
                                name: "Yogesh Narayanan",
                            },
                            {
                                "@type": "Person",
                                name: "Yathindhar Panchanathan",
                            },
                        ],
                        headquarters: {
                            "@type": "Place",
                            address: {
                                "@type": "PostalAddress",
                                addressLocality: "Chennai",
                                addressCountry: {
                                    "@type": "Country",
                                    name: "India",
                                },
                            },
                        },
                        CEO: {
                            "@type": "Person",
                            name: "Karthik Jagannathan",
                        },
                    })}
                </script>
            </Head>
            <section className="lg:pt-16 pt-10">
                <header className="lg:max-w-7xl lg:mx-auto px-5 w-full flex flex-col lg:py-16 pb-16 gap-16">
                    <div className="flex flex-col lg:flex-row lg:py-5 lg:gap-0 gap-8">
                        <div className="lg:w-1/2 w-full flex flex-col justify-start lg:gap-0 gap-4">
                            <h1 className="lg:text-new-h1 text-5xl font-bold lg:mb-6" style={{ fontFamily: "Degular Variable" }}>
                                Go from <br /> leads to deals <br /> on WhatsApp
                            </h1>
                            <p className="text-xl font-normal lg:mb-8 leading-8 lg:w-3/4">
                                Empower your sales and marketing teams to capture, qualify, and convert more leads, faster than ever before.
                            </p>
                            <CTAButtons primaryButtonText="Try for free" />
                            <div className="flex items-center lg:gap-5 gap-4 lg:mt-5 mt-0">
                                <div className="flex items-center gap-2">
                                    <Image width={24} height={24} src="https://assets.gallabox.com/gb-home/new-landing/hero-section/g2-logo.svg" alt="g2 logo" />
                                    <p className="text-sm">Rated 4.6/5 on G2</p>
                                </div>
                                <p className="text-sm">|</p>
                                <p className="text-sm">No credit card required</p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 w-full flex lg:justify-end justify-center">
                            <div className="rounded-lg overflow-hidden">
                                <LazyVideo
                                    src={"https://assets.gallabox.com/gb-home/new-landing/hero-section/hero-right-section.webm"}
                                    type={"video/webm"}
                                    poster="https://assets.gallabox.com/gb-home/new-landing/hero-section/poster.png"
                                    className="rounded-lg object-contain"
                                    style={{ maxHeight: "550px" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-full">
                        <h3 className="text-base text-center font-normal py-2">Trusted by 10,000+ businesses</h3>
                        <ClientLogos />
                    </div>
                </header>
            </section>
            <ProblemStatementSection />
            <KeyFeaturesSection />
            <FeaturesSection />
            <IntegrationSection />
            <TestimonialSection />
            <AwardsSection />
            <CTASection title="Turn connections into conversions" description="Be among the first to ride the WhatsApp automation wave" />
        </NewLayout>
    );
};

export default Home;
